<!--
Author - Rintu Kumar Chowhury
email: rintuchy.cse@gmail.com
-->
<template>
  <div>
    <h2 style="font-size: 14px; color:#0F9750;">Select Bank</h2>
    <!-- Your bank list rendering logic goes here -->
    <v-list style="max-height: 300px; overflow-y: auto;">
      <v-list-item v-for="bank in banks" :key="bank.id" class="emi-bank-class">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-list-item-content
                :class="{ 'selected-bank': bank.id === selectedBankId, 'emi-bank-clas-item-content': true }"
                @click="selectBank(bank)"
                v-on="on"
            >
              {{ getShortenedName(bank.name) }}
            </v-list-item-content>
          </template>
          <span>{{ bank.name }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>

  </div>

</template>

<script>
export default {
  props: {
    banks: Array, // Pass the array of banks as a prop
  },
  data() {
    return {
      selectedBankId: null, // Track the selected bank ID
    };
  },
  methods: {
    selectBank(selectedBank) {
      this.selectedBankId = selectedBank.id;
      this.$emit('bank-selected', selectedBank);
    },
    getShortenedName(fullName) {
      // Adjust the logic based on your requirements
      if (fullName.length > 15) {
        return fullName.substring(0, 12) + '...';
      } else {
        return fullName;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item__content {
  padding: 10px; /* Adjust the padding as needed */
}

.selected-bank {
  background-color: #0F9750; /* Change to the desired color for the selected bank */
  color: #ffffff;
}

.emi-bank-class {
  padding: 0;
  min-height: 14px;
  font-size: 14px;
}

.emi-bank-clas-item-content {
  padding: 5px 2px;
}

/* Style the tooltip */
.v-tooltip {
  max-width: none;
  white-space: nowrap;
}
</style>
