<!--
Author - Rintu Kumar Chowhury
email: rintuchy.cse@gmail.com
-->
<template>
  <div>
    <v-row>
      <v-col v-for="(group, index) in groupedBanks" :key="index" col="12" xs="12" sm="12" md="12" >
        <div v-for="bank in group" :key="bank.emiBankID" class="emi-bank-class col-md-4 col-sm-5" >
          <v-list-item>
            <v-list-item-content
                :style="{ padding: '2px', overflow: 'visible' }"
                :class="{ 'selected-bank': bank.emiBankID === selectedBankId, 'emi-bank-clas-item-content': true }"
                @click="selectBank(bank)"
            >
              <img :src="bank.emiBankLogo" :alt="bank.emiBankName">
            </v-list-item-content>
          </v-list-item>
        </div>


        <!-- Additional div for details (initially hidden) -->
        <div v-for="bank in group" :key="'bankDetails_'+bank.emiBankID" :id="'bankDetails_' + bank.emiBankID" class="bank-details col-m-table col-md-11 col-sm-11" :style="{ display: bank.emiBankID === selectedBankId ? 'block' : 'none' }">
          <!-- Details table goes here -->
          <table class="emi-details">
            <thead>
            <tr>
              <th>Tenure</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in bank.emiBankTenureDesc" :key="item.tenure">
              <td>{{ item.tenure }}</td>
              <td>{{ item.desc }}</td>
            </tr>
            </tbody>

          </table>
        </div>

      </v-col>
    </v-row>
  </div>


</template>

<script>
import axios from "axios";
const pg_url = require('../pg_url');

export default {
  props: {
    transactionID: String,
    merchantEMIBankList: Array,
  },
  data() {
    return {
      base_url: pg_url.base_url,      
      itemsPerGroup: 3,
      selectedBankId: null,
    };
  },
  created(){
    //this.fetchEMIBanks();

  },
  computed: {

    groupedBanks() {
      let result = [];
      // Check if merchantEMIBankList is populated and has the expected structure
      if (this.merchantEMIBankList && this.merchantEMIBankList.emi) {
        const banksArray = Object.values(this.merchantEMIBankList.emi);
        // Split banksArray into groups of itemsPerGroup
        for (let i = 0; i < banksArray.length; i += this.itemsPerGroup) {
          result.push(banksArray.slice(i, i + this.itemsPerGroup));
        }
      }
      return result;
    }
  },
  methods: {
    async fetchEMIBanks() {

      try {
        // Replace 'your_installments_api_endpoint' with the actual API endpoint to fetch installment data
        const response = await axios.get(`${this.base_url}installments-all`, {
          params: {
            transaction_id: this.transactionID
          },
        });

        // Assuming the response data is an array of installments
        this.merchantEMIBankList = response.data;
      } catch (error) {
        console.error('Error fetching installments:', error);
      }
    },

    selectBank(selectedBank) {
      // Toggle visibility of the details div
      if (selectedBank.emiBankID === this.selectedBankId) {
        this.hideBankDetails(selectedBank.emiBankID);
      } else {
        this.selectedBankId = selectedBank.emiBankID;
        this.$emit('display-bank-selected', selectedBank);
        this.showBankDetails(selectedBank.emiBankID);
      }
    },
    showBankDetails(bankId) {
      // Show the details div for the selected bank
      const detailsDiv = document.getElementById('bankDetails_' + bankId);
      if (detailsDiv) {
        detailsDiv.style.display = 'block';
      }
    },
    hideBankDetails(bankId) {
      // Hide the details div for the selected bank
      const detailsDiv = document.getElementById('bankDetails_' + bankId);
      if (detailsDiv) {
        detailsDiv.style.display = 'none';
      }
      this.selectedBankId = null;
    },
  },
};
</script>

<style scoped>
/* Add your styling here */
.selected-bank {
  border: 2px solid #0F9750; /* Change to the desired color for the selected bank */
  border-radius: 5px;
  color: #ffffff;
}

.v-list-item__content{

}

.emi-bank-clas-item-content img{

  max-height: 40px!important;
  max-width: 90px!important;
}

.emi-bank-class {
  /*padding: 0;*/
  /*min-height: 14px;*/
  /*font-size: 14px;*/
  float:left;
}

.v-dialog .v-card .v-card__text{
  padding: 5px 5px 5px 5px !important;
}


table.emi-details, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  padding:2px;
  border-radius: 2px;
}

table.emi-details thead th{
  color:#000;
}

.bank-details{
  float: left;
}

.col-sm-5{
  width:50%;
}

.col-m-table {
  width: 99%;
}
</style>
